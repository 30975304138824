export const SITE = {
  DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN || '',
  NAME: 'Koubo',
  CATCH_COPY: '公募/コンテスト/コンペ情報なら「Koubo」',
  DESCRIPTION:
    '公募、コンテスト、コンペ、募集情報を掲載。文芸、文学賞、アート、シンボルマーク、キャラクターデザイン、ロゴ、デザイン、イラスト、コピー、愛称、フォトコン、を年間2万件以上紹介する公募ガイド社運営のWebサイトです。',
  PUBLISHER: {
    NAME: 'Koubo Guide sha Co., Ltd.',
    LOGO_URL: '/images/ogp_koubo.png',
  },
  TWITTER: {
    SITE: '@kouboguide',
    DOMAIN: '公募ガイド社',
  },
};

export const OWNER = {
  NAME: '公募ガイド社',
  CEO: '浅田邦裕',
  TEL: '03-5405-2170',
  TEL_OPEN_HOUR: '10:00～17:00',
  FAX: '03-5405-2061',
  EMAIL: 'club@koubo.co.jp',
  ZIPCODE: '〒105-0011',
  ADDRESS: '東京都港区芝公園一丁目8番21号 芝公園リッジビル5F',
};

export const NO_IMAGE_SRC = '/images/img-noimage.png';

export const PLANNER = {
  DOMAIN: process.env.NEXT_PUBLIC_PLANNER_APP_DOMAIN || '',
};
