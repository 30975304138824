import { ApolloProvider } from '@apollo/react-hooks';
import { RecoilRoot } from 'recoil';

import type { AppProps } from 'next/app';

import { Toast } from '@/components/ui';
import { useApollo } from '@/libs/apollo';
import { TagManagerProvider } from '@/libs/ga/provider';
import { CartProvider } from '@/libs/useShoppingCart/provider';

type AppProviderProps = {
  pageProps?: AppProps['pageProps'];
  children: React.ReactNode;
};

export const AppProvider = ({ children, pageProps }: AppProviderProps) => {
  const apolloClient = useApollo(pageProps);

  return (
    <RecoilRoot>
      <TagManagerProvider>
        <ApolloProvider client={apolloClient}>
          <>
            <Toast />
            <CartProvider>{children}</CartProvider>
          </>
        </ApolloProvider>
      </TagManagerProvider>
    </RecoilRoot>
  );
};
