import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import type { AppProps } from 'next/app';

import { ApolloClientSignoutDocument } from '@/graphql/generated.creator';

export * from './util';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // NOTE: hasura graphQL error code list (ドキュメントは存在しない)
    // https://github.com/hasura/graphql-engine/blob/master/server/src-lib/Hasura/Base/Error.hs#L112
    graphQLErrors.forEach((error) => {
      switch (error.extensions.code) {
        case 'validation-failed':
          break;
        case 'unauthorized':
          break;
        case 'invalid-jwt':
        case 'access-denied':
          Sentry.captureException(error); // NOTE: 想定内だけど観測しておきたいのでしばらくはエラー通知しておく
          apolloClient
            ?.mutate({
              mutation: ApolloClientSignoutDocument,
            })
            .then(() => {
              window.location.reload();
            });
          break;
        case 'not-found':
          if (
            // NOTE: JWTの中身の変数名が追加・変更されたとき
            error.message.startsWith('missing session variable')
          ) {
            Sentry.captureException(error); // NOTE: 想定内だけど観測しておきたいのでしばらくはエラー通知しておく
            apolloClient
              ?.mutate({ mutation: ApolloClientSignoutDocument })
              .then(() => {
                window.location.reload();
              });
            break;
          }
        default: {
          Sentry.captureException(error);
        }
      }
    });
  }

  if (networkError) {
    Sentry.captureException(networkError);
  }
});

const createHttpLink = (cookie?: string) =>
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_KOUBO_API_GRAPHQL_URL,
    credentials: 'include',
    headers: cookie ? { cookie } : undefined,
  });

const createApolloClient = (cookie?: string) => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, createHttpLink(cookie)]),
    cache: new InMemoryCache({
      typePolicies: {
        query_root: { queryType: true }, // https://github.com/apollographql/apollo-client/issues/10813
      },
    }),
  });
};

export const initializeApollo = (props?: {
  cookie?: string;
  initialState?: NormalizedCacheObject;
}) => {
  const _apolloClient = apolloClient ?? createApolloClient(props?.cookie);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (props?.initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, props.initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = <T>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props: T }
) => {
  return {
    ...pageProps,
    props: {
      ...pageProps.props,
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    },
  };
};

export const useApollo = (pageProps?: AppProps['pageProps']) => {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  );
  return store;
};
