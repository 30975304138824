import { CartProvider as ShoppingCartProvider } from 'use-shopping-cart';

import type { ReactNode } from 'react';

import { StripePubKey } from '@/libs/stripe/stripe';

type Props = { children: ReactNode };

export const CartProvider = ({ children }: Props) => (
  <ShoppingCartProvider
    mode="payment"
    cartMode="client-only"
    stripe={StripePubKey}
    successUrl=""
    cancelUrl=""
    currency="JPY"
    allowedCountries={['JP']}
    billingAddressCollection
    shouldPersist
    loading={children}
  >
    {children}
  </ShoppingCartProvider>
);
