import TagManager from 'react-gtm-module';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import type { TagManagerProviderProps } from './type';

import { usePageDataLayerState } from '@/stores/dataLayer';

import { GOOGLE_TAG_MANAGER_ID, PAGE_DATA_LAYER_NAME } from './const';

export const TagManagerProvider = ({ children }: TagManagerProviderProps) => {
  useIsomorphicLayoutEffect(
    () =>
      TagManager.initialize({
        gtmId: GOOGLE_TAG_MANAGER_ID,
        dataLayerName: PAGE_DATA_LAYER_NAME,
      }),
    []
  );
  useIsomorphicLayoutEffect(
    () => document.body.classList?.remove('loading'),
    []
  );

  const { pageDataLayer } = usePageDataLayerState();

  useIsomorphicLayoutEffect(() => {
    TagManager.dataLayer({
      dataLayer: pageDataLayer,
      dataLayerName: PAGE_DATA_LAYER_NAME,
    });
  }, [pageDataLayer]);

  return children;
};
