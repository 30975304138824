import type { GoogleTagManagerId, PageDataLayer } from './type';

export const GOOGLE_TAG_MANAGER_ID = process.env
  .NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as GoogleTagManagerId;

export const PAGE_DATA_LAYER_DEFAULT = {
  contest: {
    id: undefined,
    category: undefined,
    subcategory: undefined,
  },
} satisfies PageDataLayer;

export const PAGE_DATA_LAYER_NAME = 'PageDataLayer';
