import { clsx } from 'clsx';
import toast, { resolveValue, Toaster } from 'react-hot-toast';

import { IconClose } from '@/components/icons';

export const Toast = () => {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName="!tw-z-toast"
        toastOptions={{
          duration: 5000,
        }}
      >
        {(t) => (
          <div
            className={clsx(
              'tw-flex tw-justify-between tw-items-center',
              'tw-min-w-[251px] tw-rounded-[8px] tw-shadow-[0px_4px_8px_rgba(0,0,0,0.2)]',
              'tw-py-4 tw-pl-10 tw-pr-6',
              t.type === 'error'
                ? 'tw-bg-[rgba(214,65,24,1)]'
                : 'tw-bg-[rgba(0,0,0,0.8)]',
              t.visible ? 'tw-opacity-100' : 'tw-opacity-0',
              'tw-text-white'
            )}
          >
            <span className={clsx('tw-pr-[27px]', 'tw-text-base tw-font-bold')}>
              {resolveValue(t.message, t)}
            </span>
            <button onClick={() => toast.dismiss(t.id)}>
              <IconClose boxSize="16px" />
            </button>
          </div>
        )}
      </Toaster>
    </>
  );
};
