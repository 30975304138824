import { usePathname, useSearchParams } from 'next/navigation';
import { atomFamily, useRecoilState } from 'recoil';

import type { PageDataLayer } from '@/libs/ga/type';

import { PAGE_DATA_LAYER_DEFAULT } from '@/libs/ga/const';

const pageDataLayerState = atomFamily<PageDataLayer, string>({
  key: 'pageDataLayer',
  default: PAGE_DATA_LAYER_DEFAULT,
});

export const usePageDataLayerState = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [pageDataLayer, setPageDataLayer] = useRecoilState(
    pageDataLayerState(`${pathname}?${searchParams}`)
  );
  return { pageDataLayer, setPageDataLayer };
};
