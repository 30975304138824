import { SpeedInsights } from '@vercel/speed-insights/next';
import { Noto_Sans_JP } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { useEffect } from 'react';

import '@/polyfills/arrayAt';
import 'core-js/features/string/replace-all';

import type { AppProps, NextWebVitalsMetric } from 'next/app';

import '../styles/globals.css';

import 'swiper/scss';
import 'swiper/scss/effect-fade';

import 'nprogress/nprogress.css';
import { SITE } from '@/constants';
import { useWatchVersion } from '@/hooks/utils';
import { AppProvider } from '@/providers/app';

const notoSansJapanese = Noto_Sans_JP({
  weight: ['400', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
});

const AppInit = () => {
  useWatchVersion();
  return null;
};

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const title = SITE.CATCH_COPY;
  const url = SITE.DOMAIN;
  const description = SITE.DESCRIPTION;
  const image = {
    url: `${SITE.DOMAIN}${SITE.PUBLISHER.LOGO_URL}`,
    width: 148,
    height: 148,
  };

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };

    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <AppProvider pageProps={pageProps}>
      <DefaultSeo
        title={title}
        description={description}
        openGraph={{
          url,
          title,
          description,
          type: 'website',
          images: [image],
        }}
        twitter={{
          site: SITE.TWITTER.SITE,
          handle: SITE.TWITTER.DOMAIN,
          cardType: 'summary',
        }}
      />
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
        />
      </Head>
      <div className={notoSansJapanese.className}>
        <Component {...pageProps} />
      </div>
      <AppInit />
      <SpeedInsights />
    </AppProvider>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.debug(metric);
}
